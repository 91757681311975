import React from 'react'

import { Grid, Typography } from '@mui/material'

import DashboardCard from './DashboardCard'
import { useTranslation } from '@contexts/translation'
import numberWithCommas from '@utils/numberWithCommas'
import { useAuth } from '@contexts/auth'

export default function Rent(props) {
  const { data } = props
  const { dictionary } = useTranslation()
  const { orgSettings } = useAuth()

  const percentage =
    data &&
    Math.round(
      ((data.rent_year_actual * 100) / data.rent_year_potential) * 100
    ) / 100

  return (
    <DashboardCard>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">{dictionary.my_rents}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <span
              style={{
                fontWeight: '700',
                fontSize: '1.5rem'
              }}
            >
              {data && numberWithCommas(data?.rent_year_actual)}
            </span>{' '}
            {orgSettings?.currency_symbol}/{dictionary['year_fr']}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              position: 'relative',
              width: '100%',
              borderRadius: '50rem',
              color: 'white',
              backgroundColor: 'rgb(73 154 91 / 20%)',
              margin: 2
            }}
          >
            <p
              style={{
                paddingLeft: 10
              }}
            >
              {percentage ? percentage : '0'}%
            </p>
            <span
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                display: 'inline-block',
                height: '100%',
                borderRadius: '50rem',
                // zIndex: '-1',
                minWidth: '70px',
                width: `${percentage ? percentage : '0'}%`,
                backgroundColor: '#499a5b'
              }}
            />
            <span
              style={{
                position: 'absolute',
                top: '0',
                left: 5,
                display: 'inline-block',
                height: '100%',
                borderRadius: '50rem',
                minWidth: '70px',
                width: 1
              }}
            >
              {percentage ? percentage : '0'}%
            </span>
          </div>
        </Grid>
        <Grid item xs={12} style={{ color: '#9e9e9e' }}>
          {dictionary.potential_of}{' '}
          {data && numberWithCommas(data?.rent_year_potential)}{' '}
          {orgSettings?.currency_symbol}/{dictionary.year_fr}
        </Grid>
      </Grid>
    </DashboardCard>
  )
}
