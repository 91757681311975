import React from 'react'

import { Chip, Grid, Typography } from '@mui/material'

import DashboardCard from './DashboardCard'
import { useTranslation } from '@contexts/translation'
import { Apartment, HomeWork, Hotel, House } from '@mui/icons-material'
import numberWithCommas from '@utils/numberWithCommas'

export default function Properties(props) {
  const { data } = props
  const { dictionary } = useTranslation()

  return (
    <DashboardCard>
      <Grid container spacing={1}>
        <Grid item xs={12} paddingX={2}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Typography variant="h3" sx={{ mb: 1 }}>
                {(data && data.operation_count) || 0}
              </Typography>
              <Typography variant="subtitle1">
                {dictionary.operations}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Apartment sx={{ mr: 1 }} />
                  <Typography>{`${(data && data.commercial_count) || 0} ${
                    dictionary.commercial
                  }`}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <HomeWork sx={{ mr: 1 }} />
                  <Typography>{`${(data && data.residential_count) || 0} ${
                    dictionary.residential
                  }`}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Hotel sx={{ mr: 1 }} />
                  <Typography>{`${(data && data.apartment_count) || 0} ${
                    dictionary.apartment
                  }`}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <House sx={{ mr: 1 }} />
                  <Typography>{`${(data && data.house_count) || 0} ${
                    dictionary.residential_lot
                  }`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Typography variant="subtitle1">{`${
                (data && data.lot_count) || 0
              } ${dictionary.lots}`}</Typography>

              <Typography variant="subtitle2">{`${numberWithCommas(
                (data && data.surface_area_managed) || 0
              )} m²`}</Typography>
            </Grid>
            <Grid item xs={7}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Chip
                    label={`${(data && data.office_lot_count) || 0} ${
                      dictionary.offices
                    }`}
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Chip
                    label={`${(data && data.parking_count) || 0} ${
                      dictionary.parkings
                    }`}
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Chip
                    label={`${(data && data.storage_count) || 0} ${
                      dictionary.cellar
                    }`}
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Chip
                    label={`${(data && data.residential_lot_count) || 0} ${
                      dictionary.houses
                    }`}
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardCard>
  )
}
