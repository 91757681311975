import React from 'react'

import { Grid } from '@mui/material'

//import GoogleMapStreetView from '@components/googleMap/GoogleMapStreetView'
import GMap from '@components/Projects/Map'
export default function Map() {
  return (
    <Grid
      container
      sx={{
        height: 'fit-content',
        backgroundColor: theme => theme.palette.primary.contrastText,
        borderRadius: theme => theme.spacing(1)
      }}
    >
      <Grid item xs={12}>
        {/* <GoogleMapStreetView
          style={{
            zIndex: 0,
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            position: 'relative',
            borderRadius: 5
          }}
          center={{ lat: 48.8685708, lng: 2.3302606 }}
        /> */}
        <GMap />
      </Grid>
    </Grid>
  )
}
