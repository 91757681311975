import routes from '@config/routes'
import Link from 'next/link'

import { useTranslation } from '@contexts/translation'
import { ArrowBack } from '@mui/icons-material'
import { Typography, Box, Grid, Divider, Button } from '@mui/material'

import useStyles from '@styles/layout/layout'
import { useRouter } from 'next/router'
import { useAuth } from '@contexts/auth'
import { display, flexbox } from '@mui/system'
// import HubspotTracking from '@utils/hubspotTracking'

const Layout = ({
  title = 'Title',
  subtitle = null,
  description = null,
  tabs = null,
  filters = null,
  extraHeader = null,
  children,
  view,
  setView
}) => {
  const classes = useStyles()
  const { dictionary } = useTranslation()
  const router = useRouter()
  const { user } = useAuth()

  const isPageAccount = router.asPath.includes(routes.account.root)
  const isTenantPage = user.role === 'tenant'
  const isProjectsPage = router.asPath.includes(routes.projects.summary)

  return (
    <Grid container className={classes.root}>
      {/*<HubspotTracking />*/}
      {isPageAccount && (
        <Grid
          item
          xs={12}
          sx={{ mt: theme => theme.spacing(3), ml: theme => theme.spacing(3) }}
        >
          {/* <Button startIcon={<ArrowBack />} onClick={() => router.back()}>
            {dictionary.back}
          </Button> */}
          <Link href={'/'} passHref>
            <Button startIcon={<ArrowBack />}>{dictionary.back}</Button>
          </Link>
        </Grid>
      )}
      <Grid item xs={12} className={classes.headerContainer}>
        <Box
          // className={
          //   !isPageAccount
          //     ? classes.overflowingContainer
          //     : classes.overflowingContainerAccount
          // }
          className={
            isTenantPage
              ? classes.overflowingContainerAccount
              : !isPageAccount
              ? classes.overflowingContainer
              : classes.overflowingContainerAccount
          }
        >
          <Grid
            item
            xs={12}
            sx={{
              borderRadius: theme =>
                tabs
                  ? `${theme.spacing(1)} ${theme.spacing(1)} 0px 0px`
                  : theme.spacing(1),
              display: isProjectsPage ? 'flex' : undefined,
              flexDirection: isProjectsPage ? 'row' : undefined,
              justifyContent: isProjectsPage ? 'space-between' : undefined
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Box className={classes.informationContainer}>
                {subtitle && (
                  <Typography variant="overline" color="secondary">
                    {subtitle}
                  </Typography>
                )}
                <Typography variant="h4">{title}</Typography>
                {description && (
                  <Typography variant="body1">{description}</Typography>
                )}
              </Box>
              {extraHeader && <Box sx={{ mr: 3 }}>{extraHeader}</Box>}
            </Box>
            {tabs && <Divider />}
            {tabs && tabs}
            {filters && (
              <Grid item xs={12} className={classes.filters}>
                {filters}
              </Grid>
            )}
            {isProjectsPage && (
              <Button
                sx={{ m: 4 }}
                onClick={() =>
                  view === 'list' ? setView('map') : setView('list')
                }
              >{`${view} view`}</Button>
            )}
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12} className={classes.childContainer}>
        <Box
          // className={
          //   !isPageAccount
          //     ? classes.overflowingContainer
          //     : classes.overflowingContainerAccount
          // }
          className={
            isTenantPage
              ? classes.overflowingContainerAccount
              : !isPageAccount
              ? classes.overflowingContainer
              : classes.overflowingContainerAccount
          }
        >
          {children}
        </Box>
      </Grid>
    </Grid>
  )
}

export default Layout
