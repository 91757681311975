import Image from 'next/image'
import { Box, Stack, Typography, Button } from '@mui/material'
import Link from 'next/link'

const PlaceHolder = ({
  width = 350,
  height = 350,
  src = '/Placeholders/collaboration_business.png',
  wording = '',
  wordingBordered = '',
  buttonWording = '',
  href = '',
  newWindowLink
}) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          position: 'relative'
        }}
      >
        {(buttonWording || wording || newWindowLink || wordingBordered) && (
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {wording && (
              <Typography
                align="center"
                variant="subtitle2"
                style={{ zIndex: 10, marginBottom: '1rem', maxWidth: '50%' }}
              >
                {wording}
              </Typography>
            )}
            {wordingBordered && (
              <Box
                component={'div'}
                sx={{
                  zIndex: 10,
                  width: '50%',
                  textAlign: 'center',
                  borderRadius: t => t.spacing(1),
                  p: t => t.spacing(1),
                  border: t => `1px solid ${t.palette.secondary.main}`,
                  bgcolor: 'rgba(255, 255, 255, 0.750)'
                }}
              >
                <Typography
                  align="center"
                  width={'100%'}
                  variant="subtitle2"
                  color="secondary"
                >
                  {wordingBordered}
                </Typography>
              </Box>
            )}
            {buttonWording && (
              <Link href={href} passHref>
                <Button
                  color="secondary"
                  variant="outlined"
                  sx={{
                    zIndex: 10,
                    marginTop: '1rem',
                    bgcolor: 'rgba(255, 255, 255, 0.750)'
                  }}
                >
                  {buttonWording}
                </Button>
              </Link>
            )}
            {newWindowLink && (
              <a href={href} target="_blank" rel="noreferrer">
                <Button
                  color="secondary"
                  variant="outlined"
                  sx={{
                    zIndex: 10,
                    marginTop: '1rem',
                    bgcolor: 'rgba(255, 255, 255, 0.750)'
                  }}
                >
                  {newWindowLink}
                </Button>
              </a>
            )}
          </div>
        )}
        <Image src={src} width={width} height={height} />
      </div>
    </>
  )
}

export const PlaceHolderV1 = ({
  title = '',
  width = 250,
  height = 250,
  src = '/buildings.png'
}) => {
  return (
    <Box sx={{ width, height }}>
      <img src={src} alt={`placeholder${src}`} width="100%" />
      <Stack direction="row" justifyContent="center" m={3}>
        <Typography variant="body1" color="text.secondary">
          {title}
        </Typography>
      </Stack>
    </Box>
  )
}

export default PlaceHolder
