import makeStyles from '@mui/styles/makeStyles'

export default makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3)
  },

  headerContainer: {
    background: theme.palette.elements.white,
    paddingTop: '0 !important',
    paddingLeft: '0 !important',
    margin: theme.spacing(3)
    // marginLeft: theme.spacing(6),
    // marginRight: theme.spacing(2),
    // marginTop: theme.spacing(6),
    // marginBottom: theme.spacing(2)
  },

  informationContainer: {
    height: '108px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },

  childContainer: {
    paddingTop: '0 !important',
    paddingLeft: '0 !important',
    // marginLeft: theme.spacing(4),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },

  filters: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.elements.main}`,
    borderBottom: `1px solid ${theme.palette.elements.main}`,
    backgroundColor: theme.palette.elements.light,
    overflowX: 'auto'
  },

  overflowingContainer: {
    maxWidth: `calc(100vw - ${theme.spacing(36)})`,
    flex: 1,
    [theme.breakpoints.down('md')]: {
      maxWidth: `calc(100vw - ${theme.spacing(6)})`
    }
  },

  overflowingContainerAccount: {
    maxWidth: `calc(100vw - ${theme.spacing(6)})`,
    flex: 1,
    [theme.breakpoints.down('md')]: {
      maxWidth: `calc(100vw - ${theme.spacing(6)})`
    }
  }
}))
