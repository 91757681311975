export default function numberWithCommas(number = 0, precision = 0, unit = '') {
  number = number || 0
  const parsedUnit = unit ? (unit == '%' ? unit : `\u00A0${unit}`) : ''
  let n = number
  let newNumber = Number(number.toString().replace(',', '.'))
  if (isNaN(newNumber)) {
    return Number(0).toFixed(precision)
  }
  n = Number.parseFloat(newNumber).toFixed(precision)
  const parsedNumber = n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0') // Unicode U+00A0 => non breaking space
  return parsedNumber + parsedUnit
}
