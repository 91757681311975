import React, { useState } from 'react'
import { Grid, Box, Alert, Stack, IconButton } from '@mui/material'

import { useTranslation } from '@contexts/translation'

import DashboardLayout from '@components/common/Layout'

// import Metrics from '@components/Dashboard/Global/Executive/Metrics'
// import FinancialSummary from '@components/Dashboard/FinancialSummary'
// import Event from '@components/Dashboard/Global/Executive/Event'
// import Blog from '@components/Dashboard/Global/Executive/Blog'
// import { checkPerms } from '@utils/checkPerms'
import { useAuth } from '@contexts/auth'
import useTableStyles from '@styles/table/table'
import { ExitToApp, Visibility, VisibilityOff } from '@mui/icons-material'
import CommonModal from '@components/common/Modal/CommonModal'
import HeaderActionsTable from '@components/Header/Table'
import { patchMyOrganization } from '@api/auth'
import Welcome from '@components/Dashboard/DashboardCards/Welcome'
// import Operations from '@components/Dashboard/DashboardCards/Operations'
import Properties from '@components/Dashboard/DashboardCards/Properties'
import Rent from '@components/Dashboard/DashboardCards/Rent'
import Leases from '@components/Dashboard/DashboardCards/Leases'
import Tenants from '@components/Dashboard/DashboardCards/Tenants'
import Suppliers from '@components/Dashboard/DashboardCards/Suppliers'
import Landlords from '@components/Dashboard/DashboardCards/Landlords'
import Tasks from '@components/Dashboard/DashboardCards/Tasks'
import Map from '@components/Dashboard/DashboardCards/Map'
import { useFetchData } from '@api/fetchData'

const Home = () => {
  const { dictionary } = useTranslation()
  const classesTable = useTableStyles()
  const {
    // organization,
    // organizationTeam,
    // permissions,
    orgSettings,
    profilePreferences
  } = useAuth()

  const [isSwitchingCompany, setIsSwitchingCompany] = useState(false)
  const [hideFinancials, setHideFinancials] = useState(
    !profilePreferences?.show_financials || false
  )

  const fetchUrl = `/stats/indicators/occupancy/`
  const { data, isLoading } = useFetchData(fetchUrl, null, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  })

  // const view_finance_summary = checkPerms({
  //   perm: ['view_finance_summary'],
  //   org: organization,
  //   team: organizationTeam,
  //   perms: permissions
  // })

  const handleHideFinancials = () => {
    patchMyOrganization({ show_financials: hideFinancials })
    setHideFinancials(prev => !prev)
  }

  return (
    <>
      {orgSettings?.is_demo && (
        <Box sx={{ px: 3, pt: 3 }}>
          <Alert
            severity="info"
            sx={{
              color: t => t.palette.secondary.main,
              bgcolor: t => t.palette.secondary.xtraLight
            }}
          >
            <div
              onClick={() => setIsSwitchingCompany(true)}
              style={{ cursor: 'pointer' }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <span>{dictionary.this_is_demo_account}</span>
                <ExitToApp color="secondary" fontSize="small" />
              </Stack>
            </div>
          </Alert>
        </Box>
      )}

      <DashboardLayout
        subtitle={dictionary.general}
        title={dictionary.dashboard}
        // extraHeader={
        //   <IconButton onClick={() => handleHideFinancials()}>
        //     {hideFinancials ? <VisibilityOff /> : <Visibility />}
        //   </IconButton>
        // }
      >
        <Box className={classesTable.overflowingContainer}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12}>
              <Metrics hideFinancials={hideFinancials} />
            </Grid> */}

            <Grid item xs={12} md={12}>
              <Welcome />
            </Grid>
            {/* <Grid item xs={12} md={4}>
              <Operations />
            </Grid> */}
            <Grid item xs={12} sm={6} md={4}>
              <Properties data={data} isLoading={isLoading} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Rent data={data} isLoading={isLoading} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Leases data={data} isLoading={isLoading} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Tenants />
            </Grid>
            <Grid item xs={12} md={4}>
              <Suppliers />
            </Grid>
            <Grid item xs={12} md={4}>
              <Landlords />
            </Grid>
            <Grid item xs={12} md={8}>
              <Map />
            </Grid>
            <Grid item xs={12} md={4}>
              <Tasks />
            </Grid>

            {/*

              <Grid
                item
                xs={12}
                md={8}
                container
                spacing={3}
                sx={{ height: 'fit-content' }}
              >
                {view_finance_summary && (
                  <Grid item xs={12}>
                    <FinancialSummary hideFinancials={hideFinancials} />
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                container
                spacing={3}
                sx={{ height: 'fit-content' }}
              >
                <Grid item xs={12}>
                  <Event />
                </Grid>
                <Grid item xs={12}>
                  <Blog />
                </Grid>
                <Grid item xs={12}>
                  <RecentComments />
                </Grid>
              </Grid>
            */}
          </Grid>
        </Box>
      </DashboardLayout>

      <CommonModal
        open={isSwitchingCompany}
        title={dictionary.switch_company}
        onClose={() => setIsSwitchingCompany(false)}
        maxWidth={'sm'}
      >
        <HeaderActionsTable handleClose={() => setIsSwitchingCompany(false)} />
      </CommonModal>
    </>
  )
}

export default Home
