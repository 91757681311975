import React from 'react'

import { Grid, Typography } from '@mui/material'

import DashboardCard from './DashboardCard'
import { format } from '@utils/date-fns_functions'
import PlaceHolder from '@components/common/PlaceHolder'
import { useTranslation } from '@contexts/translation'
import { useAuth } from '@contexts/auth'
import capitalizeFirstLetter from '@utils/capitalize'

export default function Welcome() {
  const { dictionary, lang } = useTranslation()
  const { user } = useAuth()

  return (
    <DashboardCard>
      <Grid container spacing={1} paddingX={2}>
        <Grid item xs={8} style={{ margin: 'auto 0' }}>
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            {capitalizeFirstLetter(format(new Date(), 'eeee', lang))}{' '}
            {format(new Date(), 'dd', lang)}{' '}
            {capitalizeFirstLetter(format(new Date(), 'MMM', lang))}{' '}
          </Typography>
          <Typography variant="h3">{`${dictionary.hello} ${
            user.first_name ?? ''
          }!`}</Typography>
        </Grid>
        <Grid item xs={4}>
          <PlaceHolder
            src={'/Placeholders/Dashboard_Welcome.png'}
            width={200}
            height={170}
          />
        </Grid>
      </Grid>
    </DashboardCard>
  )
}
