import React, { useEffect, useState } from 'react'

import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

import DashboardCard from './DashboardCard'
import { useTranslation } from '@contexts/translation'
import useTableStyles from '@styles/table/table'
import numberWithCommas from '@utils/numberWithCommas'
import PlaceHolder from '@components/common/PlaceHolder'
import { useAuth } from '@contexts/auth'
import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import getTotal from '@utils/getTotal'

export default function Suppliers() {
  const { dictionary } = useTranslation()
  const classesTable = useTableStyles()

  const { orgSettings } = useAuth()

  const [suppliers, setSuppliers] = useState([])
  const [total, setTotal] = useState(0)

  const { data, isLoading } = useFetchData(
    API_URL.SUPPLIERS.GET_SUPPLIERS_LIST()
  )

  useEffect(() => {
    if (data) {
      setSuppliers(
        data.results
          .sort((a, b) => b.balance - a.balance)
          .filter(supplier => supplier.balance > 0)
      )
      setTotal(getTotal(data.results, 'balance'))
    }
  }, [data])

  return (
    <DashboardCard>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Typography variant="h6" sx={{ pl: 2 }}>
            {dictionary.suppliers}
          </Typography>
          <Typography variant="h6" sx={{ pr: 2 }}>
            {numberWithCommas(total, 2, orgSettings?.currency_symbol || '')}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ height: 300 }}>
          <TableContainer className={classesTable.container}>
            <Table aria-label="revenue_adjustments_table">
              <TableHead>
                <TableRow hover={false}>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.name}
                  </TableCell>
                  <TableCell className={classesTable.headerCell} align="right">
                    {dictionary.balance}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableRow className={classesTable.row}>
                    <TableCell align="center" colSpan={'100%'}>
                      <CircularProgress color="secondary" />
                    </TableCell>
                  </TableRow>
                )}
                {!isLoading && !suppliers.length && (
                  <TableRow hover={false} className={classesTable.row}>
                    <TableCell align="center" colSpan={'100%'}>
                      <PlaceHolder
                        src={'/Placeholders/Dashboard_Suppliers.png'}
                        width={200}
                        height={200}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {suppliers.length > 0 &&
                  suppliers.slice(0, 6).map(supplier => (
                    <TableRow key={supplier.id} className={classesTable.row}>
                      <TableCell>{supplier.display}</TableCell>
                      <TableCell align="right">
                        {numberWithCommas(
                          supplier.balance,
                          2,
                          orgSettings?.currency_symbol || ''
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </DashboardCard>
  )
}
