import React, { useEffect, useState } from 'react'

import { Grid, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import DashboardCard from './DashboardCard'
import { useTranslation } from '@contexts/translation'
import { Line, LineChart, ResponsiveContainer } from 'recharts'
import API_URL from '@config/services'
import { useFetchData } from '@api/fetchData'
import { parse } from 'date-fns'

export default function Leases(props) {
  const { data } = props
  const { dictionary } = useTranslation()
  const theme = useTheme()

  const [graphData, setGraphData] = useState([])
  const [leases, setLeases] = useState([])
  const [expiringLeases, setExpiringLeases] = useState([])

  useEffect(() => {
    if (data) {
      const gData = []

      Object.values(data.current_leases_trimesters).map(i =>
        gData.push({ leases: i })
      )

      setGraphData(gData)
    }
  }, [data])

  let expiredLeasesCount = 0
  if (data) {
    const trimesters = data.lease_expirations_trimesters
    Object.keys(trimesters).map(trimester => {
      const value = trimesters[trimester]
      if (value !== 0) {
        expiredLeasesCount += 1
      }
    })
  }

  const fetchURL = API_URL.LEASES.GET_LEASES_LIST() + '?' + 'status=active'
  const { data: leasesData } = useFetchData(fetchURL)

  useEffect(() => {
    setLeases(leasesData?.results)
  }, [leasesData])

  useEffect(() => {
    const currentDate = new Date()
    const expiringLeasesData = leases?.filter(
      lease =>
        parse(lease.end_date, 'dd-MM-yyyy', new Date()).getFullYear() ===
        currentDate.getFullYear()
    )
    setExpiringLeases(expiringLeasesData)
  }, [leases])

  const mappedExpiringLeases = expiringLeases?.map(
    (expiringLease, idx) => `1 ${dictionary.expiring} ${expiringLease.end_date}`
  )

  return (
    <DashboardCard>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">{dictionary.my_leases}</Typography>
          <Typography variant="subtitle2">
            {dictionary.current_leases}
          </Typography>
        </Grid>
        <Grid
          item
          xs={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '3px',
            alignItems: 'flex-start',
            justifyContent: 'space-evenly'
          }}
        >
          <Typography
            variant="h1"
            style={{
              fontWeight: '300',
              paddingLeft: 7
            }}
          >
            +{(data && data.lease_signatures) || 0}
          </Typography>
          <Typography
            variant="body1"
            style={{
              color: '#9e9e9e',
              fontWeight: '500',
              fontSize: '12px'
            }}
          >
            {data?.lease_signatures > 1
              ? dictionary.leases_signed
              : dictionary.lease_signed}{' '}
            {new Date().getFullYear()}
          </Typography>
          {expiredLeasesCount !== 0 && (
            <Tooltip
              title={
                <span
                  dangerouslySetInnerHTML={{
                    __html: mappedExpiringLeases?.join('<br>')
                  }}
                />
              }
              sx={{ display: 'flex', justifyContent: 'flex-start' }}
            >
              <Typography
                variant="body"
                sx={{
                  color: theme.palette.warning.main,
                  fontWeight: '500',
                  fontSize: '12px'
                }}
              >
                +{expiredLeasesCount}{' '}
                {expiredLeasesCount > 1
                  ? dictionary.leases_expiring_in
                  : dictionary.lease_expiring_in}{' '}
                {new Date().getFullYear()}
              </Typography>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={7}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={graphData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <Tooltip cursor={{ fill: 'transparent' }} />
              <Line type="monotone" dataKey="leases" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </DashboardCard>
  )
}
