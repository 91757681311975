import React from 'react'

import { Grid } from '@mui/material'

export default function DashboardCard({ children }) {
  return (
    <Grid
      container
      padding={3}
      sx={{
        height: '100%',
        backgroundColor: theme => theme.palette.primary.contrastText,
        borderRadius: theme => theme.spacing(1)
      }}
    >
      <Grid item xs={12} container spacing={1}>
        {children}
      </Grid>
    </Grid>
  )
}
