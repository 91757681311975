import React, { useEffect, useState } from 'react'

import {
  Avatar,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

import DashboardCard from './DashboardCard'
import { useTranslation } from '@contexts/translation'
import useTableStyles from '@styles/table/table'
import PlaceHolder from '@components/common/PlaceHolder'
import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import queryString from '@utils/queryString'

export default function Tasks() {
  const { dictionary } = useTranslation()
  const classesTable = useTableStyles()

  const [tasks, setTasks] = useState([])

  const { data, isLoading } = useFetchData(
    API_URL.TASKS.GET_TASK_LIST() +
      '?' +
      queryString({ is_completed: false, is_logged: false })
  )

  useEffect(() => {
    if (data) {
      setTasks(data.results)
    }
  }, [data])

  return (
    <DashboardCard>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">{dictionary.tasks}</Typography>
          <Typography variant="subtitle2">{dictionary.tasks_desc}</Typography>
        </Grid>
        <Grid item xs={12} style={{ height: 400, overflow: 'auto' }}>
          <TableContainer className={classesTable.container}>
            <Table aria-label="revenue_adjustments_table">
              <TableHead>
                <TableRow hover={false}>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.title}
                  </TableCell>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.date}
                  </TableCell>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.owner}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableRow className={classesTable.row}>
                    <TableCell align="center" colSpan={'100%'}>
                      <CircularProgress color="secondary" />
                    </TableCell>
                  </TableRow>
                )}
                {!isLoading && !tasks.length && (
                  <TableRow hover={false} className={classesTable.row}>
                    <TableCell align="center" colSpan={'100%'}>
                      <PlaceHolder />
                    </TableCell>
                  </TableRow>
                )}
                {tasks.length > 0 &&
                  tasks.slice(0, 8).map(task => (
                    <TableRow key={task.id} className={classesTable.row}>
                      <TableCell>{task.title}</TableCell>
                      <TableCell>{task.due_date}</TableCell>
                      <TableCell>
                        <Avatar>
                          {task.assigned_to?.display?.[0]?.toUpperCase()}
                        </Avatar>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </DashboardCard>
  )
}
